
// *[data-show] {
// 	display: none;
// }


.selected {
	background-color: #66b93f !important;
}


.report-date-picker {
  font-size: 22px;
//   font-weight: 700;
  text-align: center;
  width: 200px !important;
}
.trend-date-picker {
  font-size: 22px;
//   font-weight: 700;
  text-align: center;
  width: 290px !important;
}

.date-mover {
	background: none;
	font-size: 24px;
	color: #2a3238;
	padding: 0;
	&:hover, &:focus {
		background: none;
	}
}

#daySelection {
	margin: 0 4px;
	background-color: rgba(0, 0, 0, 0);
	border: none;
	border-bottom: 1px solid black;
	cursor: pointer;
	width: initial;
	height: initial;
	border-radius: 0;
	&:focus {
		outline: none;
	}
}

#graphData {
	width: 100%;
}

#loading {
	text-align: center;
}


.loading-path {
	stroke-dasharray: 106; 
	stroke-dashoffset: 106;
	animation: dash 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
}

@keyframes dash {  
  from {
    stroke-dashoffset: 106;
  }
  to {
    stroke-dashoffset: 0;
  }
}

.loading-text {
	text-align: center;
}

.graph-legend {
	display: flex;
	text-align: center;
	margin: 12px 0 6px;
	justify-content: center;
	gap: 20px;
}

.graph-legend-circle {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin: 2px auto;
	border-radius: 8px;
	border: 1px solid black;
}

.graph-legend-square {
	display: inline-block;
	width: 16px;
	height: 16px;
	margin: 2px auto;
	border: 1px solid black;
}

table.dass21-scoring {
	margin: 0 auto;
	border-collapse: collapse;
	td, th {
		padding: 3px 8px;
		text-align: center;
	}
}

#report {
	height: 500px;
}

#trends {
	height: 600px;
}

.trend-metrics {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	row-gap: 12px;
}

.secondary-button.moreinfo {
  padding: 12px 20px;
  color: #fff;
  line-height: 12px;
}

.show-last {
	grid-column-start: 1;
	grid-column-end: 4;
}

#graphics {
	display: grid;
	grid-auto-columns: 1fr 1fr;
	grid-template-columns: 1fr 1fr;
	text-align: center;
	margin-top: 12px;
}

.score-pie svg {
	width: 140px;
	height: 98px;
}

.graph-controls {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
  }
  
.graph-controls-left {
	display: flex;
	gap: 12px;
	flex-direction: row;
	align-items: center;
}

.react-datepicker__day {
	border: 2px solid #00000000;
}
.datepicker-populated-partial {
	border: 2px solid #7ACE4C;
}
.datepicker-populated-full {
	background-color: #7ACE4C;
	color: white;
	&:hover {
		background-color: darken(#7ace4c, 8%);
	}
	&.react-datepicker__day--in-range {
		border: 2px solid #216ba5;
	}
	&.react-datepicker__day--in-selecting-range {
		background-color: #7ACE4C;
		border: 2px solid #94b5d3;
	}
}

.graph-controls-right {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 20px;
	button {
		width: 90px;
	}
}

#commentSections {
	display: flex;
	gap: 16px;
}
  
#comments {
	max-height: 200px;
	overflow: scroll;
	position: relative;
	margin: 0 0 8px;
	padding-bottom: 20px;
}

.comments-gradient {
	content: "";
	display: block;
	position: relative;
	bottom: 38px;
	margin-bottom: -30px;
	width: 100%;
	height: 30px;
	background: rgb(255,255,255);
	background: linear-gradient(0deg, rgba(255,255,255,1) 30%, rgba(255,255,255,0) 100%);
}

li.comment {
	display: grid;
	grid-template-columns: 160px 1fr 20px;
	column-gap: 12px;
	list-style: none;
	margin: 8px 0;

	.comment-author-wrap {
		border-right: 1.5px solid #ccc;
	}

	.comment-author {
		font-weight: bold;
	}

	.comment-timestamp {
		font-size: 12px;
		color: #666;
	}

	.comment-delete {
		margin: 10px auto 0;
		cursor: pointer;
	}
}


#systemComment {
	display: flex;
    flex-direction: column;
    justify-self: left;
}


.stats-grid {
	display: grid;
	grid-auto-columns: 1fr;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto auto;
	grid-row-gap: 16px;
	grid-column-gap: 16px;
	.stats-grid-span-2 {
		grid-column-start: 1;
		grid-column-end: 3;
	}
}

.overview-grid {
	grid-template-columns: 1fr 1fr;
}
  
.sleep-grid {
	grid-template-columns: 1fr 1fr 1fr;
}

.interruptions-grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}

.sleep-period-text {
	grid-column-start: 2;
	grid-row-start: 1;
	grid-column-end: 5;
}

.sleep-interruptions-text {
	grid-column-start: 5;
	grid-row-start: 1;
	grid-column-end: 7;
}
  
.spiking-grid {
	grid-template-columns: 1fr 1fr 1fr 1fr;
}

.grid-title {
	font-size: 20px;
	line-height: 26px;
	border-bottom: 2px solid #777;
	margin-bottom: 6px;
	padding-bottom: 2px;
}

td, th {
	border: 1px solid black;
	padding: 4px 2px;
}

@media screen and (max-width: 479px) {
	.graph-controls {
		height: auto;
		flex-direction: column;
	}

	.graph-controls-left {
		margin-bottom: 10px;
	}

	.graph-controls-right {
		width: 100%;
		margin-bottom: 10px;
		justify-content: center;
	}
}

@media screen and (max-width: 767px) {
	#commentSections {
		display: grid;
		grid-template-columns: 1fr;
	}
}

@media print {
	.secondary-button {
		display: none;
	}
	nav {
	  display: none;
	}
	main {
	  margin-left: 0;
	}
}