@import "/src/css/variables";

.dialog-title {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr auto;
    margin: auto;

    p {
        font-size: larger;
        margin: auto;
    }

    .dialog-close {
        cursor: pointer;
        margin-left: auto;
        padding: 0.5rem;
    }
}
